@media only screen and (max-width: 800px) {
  .list-layout {
    display: "flex";
    flex-direction: column;
  }
}

@media only screen and (min-width: 800px) {
  .list-layout {
    display: "flex";
    width: 50vw;
  }
}
