.div-challenge-list {
    display: flex;
    width: 90%;
    min-height: 200px;
    align-self: center;
    flex-direction: column;
    position: relative;
}

.div-challenge-list-two {
    display: flex;
    width: 100%;
    min-height: 250px;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    flex-direction: row;
    overflow-x: auto;
}

.div-challenge-list-two-mobile {
    display: flex;
    width: 100%;
    min-height: 200px;
    align-self: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;
    position: relative;
    flex-direction: row;
    overflow-x: auto;
}

.div-challenge-list-two::scr

.div-challenge-list-mobile {
    display: flex;
    width: 100%;
    height: 100px;
    align-self: center;
    flex-direction: row;
    overflow-x: scroll;
}

.div-element-list-mobile-two {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 300px;
    min-width: 300px;
    margin-top: 10px;
    flex-direction: row;
    margin: 3px;
}

.div-challenge-text {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
    width: 90%;
    z-index: 1;
    align-items: center;
    position: absolute;
    top: 20px;
}

.div-challenge-list {
    display: flex;
    justify-content: center;
}

.div-element-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    min-width: 400px;
    flex-direction: row;
}

.div-element-list-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.div-element-list-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    width: 100%;
    flex-direction: column;
}