.div-home-info-user-card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 120px;
    width: 100%;
    flex-direction: column;
    flex-direction: row;
    margin-top: -15px;
}

.line-vertical {
    height: 70%;
    width: 1px;
    background-color: #37363B;
}

.div-name-my-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.div-group-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: row;
}

.div-home-info-user-card-mobile {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.div-icons-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}