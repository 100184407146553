.div-scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 80%;
    height: 245px;
    align-self: center;
}

.div-scoreboard-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    height: 245px;
    align-self: center;
}

.card-friends {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border-radius: 10px;
}

.image-friends {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.card-friends-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
    height: 100%;
    border-radius: 10px;
}

.div-scoreboard-cards-2 {
    list-style-type: none; 
    padding: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-direction: row;
    overflow: auto;
    margin-left: 20px;
    margin-right: 20px;
}

.div-scoreboard-list{
    justify-content: center;
    align-items: center;
    list-style-type: none; 
    padding: 0; 
    display: flex;
    flex-direction: row;
    width: 100%;
}


.div-scoreboard-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 50px;
    width: 100%;
    height: 50px;
}