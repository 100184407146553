@media screen and (max-width: 800px) {
    :where(.css-dev-only-do-not-override-yzacnn).ant-select:not(.ant-select-customize-input) .ant-select-selector,
    :where(.css-dev-only-do-not-override-yzacnn).ant-select:not(.ant-select-customize-input) .ant-select-selector:focus {
        background-color: #0A0C0D;
        border: #0A0C0D;
    }
    :where(.css-dev-only-do-not-override-yzacnn).ant-select-dropdown,
    :where(.css-dev-only-do-not-override-yzacnn).ant-select-dropdown:focus {
        background-color: #0A0C0D;
        border: #0A0C0D;
    }
}