.root-progress-point-watch-video {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 90%;
    align-self: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.root-progress-point-watch-video-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 96%;
    align-self: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.group-line-progress-point-watch-video {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.group-line-text--progress-point-watch-video {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}