:where(.css-dev-only-do-not-override-14bc36b).ant-collapse-borderless >.ant-collapse-item {
    border: none;
}
.div-collapse-faq-panel-space{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #121617;
}

@media screen and (max-width: 769px) {
    .div-collapse-faq {
        width: 90vw !important;
        justify-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
    }

    .div-collapse-faq-panel {
        width: 100%;
        border: none;
    }

    .div-collapse-faq-panel-space {
        width: 100%;
        border: none;
    }
    .show-menu-icon {
        display: none;
    }
}
