.div-product-card {
  min-width: 250px;
  max-width: 250px;
  min-height: 500px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a0c0d;
  flex-direction: column;
  border-radius: 10px;
}

.image-product-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  max-height: 250px;
  width: 90%;
  object-fit: cover;
  border-radius: 10px;
  background-color: white;
}

.div-product-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1.5;
  height: 100%;
  width: 100%;
  background-color: "red";
}

.div-product-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin-bottom: 2%;
}

.div-product-card-content-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 90%;
  flex-direction: row;
}

.div-product-card-line {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  background-color: #000000;
}

.div-product-card-available-amount {
  display: flex;
  height: 35px;
  justify-content: center;
  flex-direction: column;
  align-items: start;
}

.div-product-card-infos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 90%;
  flex-direction: row;
}

.div-product-card-rare-coins {
  background-color: #df8c0f;
  width: 120px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}

.div-product-card-normal-coins {
  background-color: #cb1a34;
  width: 120px;
  height: 30px;
  border-radius: 10px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
}