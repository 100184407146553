.div-footer {
  height: 300px;
  width: 96%;
  align-self: center;
}

.div-footer-sponsor {
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  border-top: 1px solid #37363b;
  border-bottom: 1px solid #37363b;
  gap: 20px;
}

.image-sponsor {
  width: 100px;
  height: 80px;
  margin: 5px;
  object-fit: contain;
}

.div-footer-two {
  display: flex;
  height: 100%;
  width: 100%;
}

.div-footer-two-one {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.div-footer-two-two {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.div-footer-two-three {
  flex: 0.5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
  gap: 10px;
}

.div-footer-two-four {
  flex: 0.4;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  flex-direction: column;
  gap: 10px;
}

.div-image-logo {
  width: 90%;
  display: flex;
  justify-content: flex-start;
}

.div-footer-two-two-father-grid {
  display: flex;
  flex: 1;
  width: 100%;
}

.div-footer-two-two-grid {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
}

.div-footer-social {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.footer-logo-social {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.div-image-footer-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.div-footer-two-two-mobile {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.div-footer-mobile {
  width: 96%;
  align-self: center;
}

.div-footer-social-mobile {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.link-style {
  color: #ffffff;
  text-decoration: none;
  font-family: "Urbanist";
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
