.frame-watch-video {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}