.div-missions-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    max-width: 270px;
    min-height: 500px;
    max-height: 600px;
    margin: 50px;
    background-color: #0A0C0D;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.div-missions-card-normal {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 270px;
    max-width: 270px;
    min-height: 500px;
    max-height: 600px;
    margin: 50px;
    background-color: #0A0C0D;
    flex-direction: column;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
}

.div-missions-card::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    width: 500%;
    height: 20px;
    background: linear-gradient(to right, #fff 0%, #f5ed9768 50%, transparent 100%);
    box-shadow: 1px 2px 40px;
    animation: moveLine 10s linear infinite;
    rotate: 45deg;
}

@keyframes moveLine {
    0%, 75% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(900px);
    }
    /* to {
        transform: translateY(800px);
    } */
    /* 0%, 100% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(800px);
    } */
}

.div-missions-card-one {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1.5;
    height: 100%;
    width: 100%;
}

.div-missions-card-two {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 100%;
    flex-direction: column;
}

.image-missions-card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 230px;
    max-height: 230px;
    width: 90%;
    object-fit: cover;
    border-radius: 10px;
}

.div-missions-card-line {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    background-color: #121617;
}

.div-missions-card-two-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 90%;
    flex-direction: row;
}

.div-missions-card-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 90%;
    flex-direction: row;
}

.div-missions-card-infos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    width: 90%;
    flex-direction: row;
}

.div-missions-card-info-time {
    background-color: #000;
    width: 100px;
    height: 35px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 5px;
}