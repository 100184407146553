@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');

.App {
  text-align: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Personaliza toda a barra de rolagem */
::-webkit-scrollbar {
  width: 7px; /* Largura da barra de rolagem */
  height: 7px;
}

/* Personaliza o fundo da barra de rolagem */
::-webkit-scrollbar-track {
  background: #0e0e0e; /* Cor de fundo, escolhida para combinar com o fundo escuro do site */
}

/* Personaliza o botão de rolagem (a alça) */
::-webkit-scrollbar-thumb {
  background: #b2062c; /* Cor do botão de rolagem, um vermelho escuro para combinar com os elementos do site */
  border-radius: 6px; /* Borda arredondada para o botão de rolagem */
}

/* Personaliza o estado de passar o mouse sobre o botão de rolagem */
::-webkit-scrollbar-thumb:hover {
  background: #f30a2d; /* Cor mais clara do botão de rolagem ao passar o mouse, para manter a paleta de cores */
}