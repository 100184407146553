body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-border {
  background: linear-gradient(black, black) padding-box,
    linear-gradient(to right, #B3122A, #CA1BA3) border-box;
  border-radius: 50em;
  border: 2px solid transparent;
}


.div-card-how-it-works {
  width: 80vw;
}

@media screen and (max-width: 878px) {
  .div-card-how-it-works {
    width: 60vw;
  }

}

@media screen and (min-width: 878px) {
  .div-card-how-it-works {
    width: 280px;
  }
}