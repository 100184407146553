.info-menu-five {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;
    flex-direction: column;
}

.image-menu-five {
    height: 60px;
    width: 60px;
    object-fit: cover;
    margin-right: 4px;
    border-radius: 10px;
}

.info-menu-five-one {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.info-menu-five-two {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.space-info-menu-five {
    margin-left: 5px;
    margin-right: 5px;
}

.div-menu-five {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: .7;
    height: 100%;
    cursor: pointer;
}

.div-menu-five-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}