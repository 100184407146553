.info-user-home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 200px;
    flex-direction: column;
    flex-direction: row;
}

.info-user-home-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 200px;
    flex-direction: column;
    flex-direction: row;
}

.info-user-home-one {
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.info-user-home-two {
    flex: 2;
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    justify-content: space-evenly;
}

.image-info-user-home {
    height: 38px;
    width: 38px;
}

.info-user-home-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 120px;
    flex-direction: column;
    flex-direction: row;
}

.image-info-user-home-mobile {
    height: 24px;
    width: 24px;
}