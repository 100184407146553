.div-card-earn-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: 470px;
    margin-top: 10px;
    flex-direction: row;
    border-radius: 10px;
    flex-direction: column;
    background-color: #121617;
    padding: 20px;
    cursor: pointer;
    position: relative;
}

@media screen and (max-width: 800px) {
    .div-card-earn-videos {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 250px;
        height: 470px;
        margin-top: 10px;
        flex-direction: row;
        border-radius: 10px;
        flex-direction: column;
        background-color: #121617;
        padding: 20px;
        cursor: pointer;
        position: relative;
    }
}

.div-card-earn-videos-image {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.img-card-earn-videos {
    width: 100%;
    height: 96%;
    object-fit: cover;
    border-radius: 10px;
}

.div-logo-card-earn-videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    background-color: black;
}

.logo-card-earn-videos {
    width: 40px;
    height: 40px;
    border-radius: 10px;
}

.div-card-earn-videos-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-direction: row;
}

.div-card-earn-videos-info-one {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-card-earn-videos-info-two {
    flex: 2;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-left: 5px;
}

.div-card-earn-videos-info-three {
    flex: 1.5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-card-earn-videos-info-four {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div-info-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    flex-direction: row;
    border-radius: 10px;
    flex-direction: column;
}

.div-win-at-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-direction: row;
    flex-direction: column;
    align-items: flex-end;
}