.div-scoreboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    height: 380px;
    align-self: center;
}

.div-scoreboard-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    height: 380px;
    align-self: center;
}

.card-friends {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    border-radius: 10px;
}

.image-friends {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.card-friends-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 150px;
    height: 100%;
    border-radius: 10px;
}

.div-scoreboard-cards {
    display: flex;
    flex-direction: row;
    width: 92%;
    overflow: scroll;
    margin-top: 20px;
    align-items: center;
}

.div-scoreboard-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 50px;
    width: 92%;
    height: 30px;
}
.red-xp-text {
    color: #FE304E;
}