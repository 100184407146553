.div-detail-missoin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    align-self: center;
    height: 600px;
}

.image-detail-mission {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.div-children-info-mission-detail {
    background-color: #0A0C0D;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 10px;
}