.div-modal-home-welcome {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 600px;
    gap: 10px;
}

.image-modal-home-welcome {
    width: 100%
}