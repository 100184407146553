.home-base {
    display: flex;
    width: 100vw;
    background-color: #000000;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
}

.custom-button {
    background: linear-gradient(black, black) padding-box, linear-gradient(to right, #B3122A, #CA1BA3) border-box;
    border-radius: 24px !important;
    border: 2px solid transparent;
    text-align: center;
    color: #FFFFFF;
    width: 214px;
    min-height: 48px;
    flex-shrink: 0;
}

@media (max-width:480px) {
    .custom-button .custom-button2{
        width: 90%;
        border-radius: 24px !important;
        border: 2px solid transparent;
        text-align: center;
        color: #FFFFFF;
        min-height: 48px;
        flex-shrink: 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .ant-tour-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        gap: 10px;
    }
    .ant-tour-content {
        align-self: center;
        justify-self: center;
    }
}

.custom-button2 {
    align-self: center;
    justify-self: center;
    border-radius: 24px !important;
    border: 2px solid transparent;
    text-align: center;
    width: 214px;
    min-height: 48px;
    flex-shrink: 0;
    background-color: #121617 !important;
    border: #CA1BA3 !important;
    color: white;
}

.custom-button2:hover {
    background: linear-gradient(black, black) padding-box, linear-gradient(to right, #B3122A, #CA1BA3) border-box;
    color: white !important;
    border: #CA1BA3 !important;
}