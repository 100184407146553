.container-step-by-step-use-coins {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 1;
    max-width: 90vw;
}

.Box-Step-By-Step-one {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    height: 50%;
    width: 90%;
}

.Box-Step-By-Step-two {
    flex-direction: row-reverse;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
    height: 50%;
    width: 90%;
}



@media screen and (max-width: 850px) {
    .container-step-by-step-use-coins {
        flex-direction: column;
        justify-content: center;
        width: 90vw;
        align-items: center;
        justify-content: center;
        gap: 2%;
    }
    .Box-Step-By-Step-one {
        flex-direction: column;
        gap: 2%;
        justify-content: center;
        align-items: center;
    }
    .Box-Step-By-Step-one-text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .Box-Step-By-Step-two {
        flex-direction: column;
        gap: 2%;
        justify-content: center;
        align-items: center;
    }
    .Box-Step-By-Step-two-text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}