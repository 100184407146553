.background-login {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media screen and (max-width: 800px) {
    .image-login {
        background-image: url("https://redcanidsstorage.blob.core.windows.net/redcanids/1536ff23-012c-4949-8cb6-95b831ad6e8a.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: brightness(0.3);
        object-fit: contain;
        background-position: center;
    }
}

@media screen and (min-width: 800px) {
    .image-login {
        background-image: url("https://redcanidsstorage.blob.core.windows.net/redcanids/1536ff23-012c-4949-8cb6-95b831ad6e8a.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: brightness(.3);
        object-fit: contain;
        background-position: center;
    }
}

.root-login {
    height: 80%;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.logo-login {
    height: 100px;
    width: 100px;
    object-fit: contain;
}

.div-logo-login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: 100px;
    width: 100px;
}

.input-login {
    width: 90%;
    max-width: 800px;
    margin-top: 10px;
}

.button-login {
    width: 800px;
}