.menu-default-base {
    height: 90px;
    min-height: 60px;
    width: 96vw;
    display: "flex";
    justify-content: "center";
    overflow-x: hidden;
    align-self: center;
}

@media only screen and (max-width: 800px) {
    .menu-one {
        display: flex;
        align-self: center;
        justify-content: flex-start;
        display: flex;
        margin-left: 20px;
        cursor: pointer;
    }
}

@media only screen and (min-width: 800px) {
    .menu-one {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        min-width: 70px;
        max-width: 70px;
        cursor: pointer;
    }
}



@media only screen and (max-width: 800px) {
    .menu-two {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 800px) {
    .menu-two {
        flex: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.menu-three {
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-four {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-five {
    width: 210px;
    min-width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu-five-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}

.home-logo {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

.image-farmar-xp{
    height: 13px;
    width: 13px;
}

.div-menu-five {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.image-menu-five {
    height: 30px;
    width: 30px;
    object-fit: contain;
    height: 100%;
}