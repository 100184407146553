.div-border {
    border: 1px solid #37363B;
    width: 320px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
}

.div-border-mobile {
    border: 1px solid #37363B;
    width: 300px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
}

.div-border-select-mobile {
    border: 3px solid #B3122A;
    width: 300px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
}

.div-border-select {
    border: 3px solid #B3122A;
    width: 320px;
    height: 102px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
}

:where(.css-dev-only-do-not-override-yzacnn).ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #B3122A;
    background-color: #B3122A;
}

/* Adicione isso ao seu arquivo de estilos CSS */
.image-profile.selected {
    border: 2px solid #B3122A;
    /* Cor da borda quando selecionada */
}