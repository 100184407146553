@media only screen and (min-width: 1024px) {
  .carousel-styles {
    height: 550px;
    width: 100%;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .carousel-styles {
    height: 550px;
    min-width: 250px;
    max-width: 250px;
    margin: auto;
  }
}
