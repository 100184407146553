@media only screen and (min-width: 800px) {
  .root-finder-mission-template {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: center;
    height: 460px;
  }

  .div-template-content {
    width: 100%;
    flex-direction: column;
    margin-top: 16px;
  }

  .validate-button {
    width: 240px;
    background-color: #b3122a;
    border-radius: 30px;
    margin-top: 16px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

@media only screen and (max-width: 800px) {
  .root-finder-mission-template {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: center;
    height: 460px;
  }

  .div-template-content {
    width: 100%;
    flex-direction: column;
  }

  .validate-button {
    width: 240px;
    background-color: #b3122a;
    border-radius: 30px;
    margin-top: 16px;
    height: 40px;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .div-title {
    width: 90%;
    justify-content: center;
    align-self: center;
  }
}
